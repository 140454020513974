<template>
  <section id="about_us">
    <div class="about_us_decor_bg">
      <img src="../../assets/imgs/index/about_bg.png" alt="">
    </div>
    <div class="about_us_wrapper w1400">
      <div class="about_us_main">
        <TitleMain
          :textMain="'ABOUT US'"
          :textSub="'關於六協'"
          :leftDecor="false"
          :rightDecor="true"
          class="section_title txt-left"
        />
        <div class="about_us_pic mo">
          <img :src="decorPic" alt="">
        </div>
        <div class="about_us_content">
          <div class="about_us_content_title h4 txt-main">
            {{ lang==='TW'?'堅持品質的理念':'Quality is priority'}} <br>
            {{ lang==='TW'?'專業創新的技術':'Innovative & professional' }}
          </div>
          <div class="about_us_content_description h6">
            {{ lang==='TW'? dataTW : dataEN }}
          </div>
          <BtnMain
            :text="'READ MORE'"
            :btnType="1"
            @click.native="clickHandler()"
          />
        </div>
      </div>
      <div class="about_us_pic pc">
        <img :src="decorPic" alt="">
      </div>
    </div>
  </section>
</template>

<script>
import TitleMain from '@/components/TitleMain.vue';
import BtnMain from '@/components/BtnMain.vue';
import decorPic from '@/assets/imgs/index/img_2.png';
import languageMixin from '@/mixins/languageMixin';

export default {
  name: 'AboutUs',
  mixins: [languageMixin],
  components: {
    TitleMain,
    BtnMain,
  },
  data() {
    return {
      decorPic,
      dataTW: '擁有最先進的製刀設備、技術及優秀的研發團隊， 全由訓練有素的工作人員，層層把關一貫作業完成。',
      dataEN: 'Working with most advanced manufacturing machineries, excellent R&D team, and well-trained staffs to ensure every Atlantic Chef knife meets top quality standards.',
    };
  },
  methods: {
    clickHandler() {
      this.$router.push({ name: 'About' });
    },
  },
};
</script>
